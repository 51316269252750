import React from "react";
import Layout from "../components/layout";
import HeaderTwo from "../components/header/header-two";
import StickyHeader from "../components/header/sticky-header";
import PageHeader from "../components/page-header";
import AboutOne from "../components/about/about-one";
import AboutCounter from "../components/about/about-counter";
import TeamHome from "../components/team/team-home";
import VideoCard from "../components/videos/video-card";
import TestimonialsOne from "../components/testimonials/testimonials-one";
import BrandCarousel from "../components/brand-carousel";
import Footer from "../components/footer";

const About = () => {
  return (
    <Layout pageTitle="O NAS - Auto Serwis - Tomasz Jatkowski">
      <HeaderTwo />
      <PageHeader title="O NAS" crumbTitle="O Nas" />
      <StickyHeader />
      <AboutOne />
      <Footer />
    </Layout>
  );
};

export default About;
