import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import about1 from "../../assets/images/shapes/about-bag-1-1.png";
import about2 from "../../assets/images/resources/about-1-1.jpg";
import about3 from "../../assets/images/resources/about-1-2.jpg";
import heart from "../../assets/images/shapes/heart-2-1.png";

const AboutOne = () => {
  return (
    <section className="about-one pt-120 pb-40">
      <Container>
        <Row>
          <div className="about-one__award">
            <img src={about1} alt="" />
          </div>
          <Col lg={6}>
            <img src={about2} alt="" className="img-fluid" />
          </Col>
          <Col lg={6}>
            <img src={about3} alt="" className="img-fluid" />
          </Col>
        </Row>
      </Container>
      <Container>
        <div className="team-about__top mt-60">
          <Row>
            <Col md={12} lg={4}>
              <div className="block-title">
                <p>
                  <img src={heart} width="50" alt="" />
                  Robimy różnice
                </p>
                <h3>Niesiemy pomoc uszkodzonym autom</h3>
              </div>
            </Col>
            <Col md={12} lg={4}>
              <p className="team-about__top-text">
                Nasza oferta skierowana jest do wszystkich zainteresowanych kompleksową obsługą pojazdów. Obsługujemy zarówno klientów indywidualnych, jak i firmy posiadające <strong>flotę samochodową.</strong> W przypadku obsługi flot, proponujemy <strong>korzystne warunki finansowe.</strong> Wysoki poziom usług zapewniają nam nasi <strong>wykwalifikowani pracownicy,</strong> posiadający aktualne informacje o technologii i najnowszych sposobach obsługi pojazdów. Warsztat wyposażony jest w <strong>profesjonalny, specjalistyczny sprzęt.</strong> Serwisujemy wszystkie marki samochodów, bez względu na wiek czy stan techniczny. 
              </p>
            </Col>
            <Col md={12} lg={4}>
              <p className="team-about__top-text">
               Proponujemy Klientom części oryginalne oraz ich tańsze zamienniki. Posiadamy zatem ofertę <strong>dla wszystkich Klientów,</strong> także tych, którzy nie dysponują środkami na zakup droższych części. Naszym celem jest <strong>perfekcyjna obsługa klienta,</strong> czyli dostarczanie usług i części wysokiej jakości po atrakcyjnej cenie. Nasza firma jest płatnikiem VAT - wystawiamy faktury. Zapewniamy miłą i sprawną obsługę oraz ceny konkurencyjne.
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default AboutOne;
